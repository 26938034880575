<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      background-color="#fff"
      :is-full-page="false"
    ></loading>
    <b-alert v-model="error" variant="danger" dismissible>
      {{ errMsg }}
    </b-alert>
    <CButton
      class="wp-button"
      name="Whatsapp"
      size="lg"
      color="xing"
      target="_blank"
      @click.prevent="whatsapp()"
    >
      <CIcon size="lg" :name="'cib-Whatsapp'" />
    </CButton>

    <div v-if="service != null">
      <CModal :show.sync="flagShowVideo" size="xl" title="Vídeo">
        <b-row class="">
          <b-col md="12">
            <b-embed
              type="iframe"
              aspect="16by9"
              :src="service.youtubeUrl"
              allowfullscreen
            ></b-embed>
          </b-col>
        </b-row>
        <template v-slot:footer-wrapper>
          <div></div>
        </template>
        <!-- <template v-slot:footer>
          <div></div>
        </template> -->
      </CModal>
    </div>

    <b-row v-if="!loading">
      <b-col lg="12">
        <!-- <b-col lg="10" offset-lg="1"> -->
        <CCard style="padding: 4rem;">
          <!-- Card infro and carousel -->
          <section>
            <b-row>
              <!-- Card info -->
              <b-col lg="5">
                <b-jumbotron
                  class="p-bg p-bg-text service-banner"
                  :style="{ backgroundImage: `url(${bannerImage})` }"
                >
                  <b-row>
                    <b-col lg="10" offset-lg="1" md="12">
                      <b-row>
                        <b-col lg="12">
                          <h2>{{ service.name }}</h2>
                          <h3 class="s-text-dark">
                            <strong>
                              <span v-if="service.type == 1">
                                <small>Desde </small>
                              </span>
                              <span v-if="userCurrency == 'COP'"
                                >{{ service.currencyCop | thousand }} COP</span
                              >
                              <span v-if="userCurrency == 'PEN'"
                                >{{ service.currencyPen | thousand }} PEN</span
                              >
                            </strong>
                          </h3>
                          <!-- Tag quantify, model and color -->

                          <div v-if="service.type == 2">
                            <TagFeatures
                              v-if="service.type == 2 && !loading"
                              :flagTagFeatures="flagTagFeatures"
                              :serviceId="serviceId"
                              @modelSelected="tagFeatures.model = $event"
                              @colorSelected="tagFeatures.color = $event"
                              @sizeSelected="tagFeatures.size = $event"
                              @stockAvailable="
                                tagFeatures.stockAvailable = $event
                              "
                              @flagTagFeatures="flagTagFeatures = $event"
                            />
                            <b-row style="margin-bottom:0.5rem;">
                              <b-col lg="5">
                                Modelo:
                              </b-col>
                              <b-col lg="7">
                                <b-button
                                  size="sm"
                                  @click="flagTagFeatures = true"
                                  class="mr-1 w-100"
                                >
                                  {{
                                    tagFeatures.model
                                      ? tagFeatures.model
                                      : "Seleccionar"
                                  }}
                                </b-button>
                              </b-col>
                            </b-row>

                            <b-row style="margin-bottom:0.5rem;">
                              <b-col lg="5">
                                Talla:
                              </b-col>
                              <b-col lg="7">
                                <b-button
                                  size="sm"
                                  @click="flagTagFeatures = true"
                                  class="mr-1 w-100"
                                >
                                  {{
                                    tagFeatures.size
                                      ? tagFeatures.size
                                      : "Seleccionar"
                                  }}
                                </b-button>
                              </b-col>
                            </b-row>

                            <b-row style="margin-bottom:0.5rem;">
                              <b-col lg="5">
                                Color:
                              </b-col>
                              <b-col lg="7">
                                <b-button
                                  size="sm"
                                  @click="flagTagFeatures = true"
                                  class="mr-1 w-100"
                                >
                                  {{
                                    tagFeatures.color
                                      ? tagFeatures.color
                                      : "Seleccionar"
                                  }}
                                </b-button>
                              </b-col>
                            </b-row>

                            <b-row>
                              <b-col lg="5">
                                Cantidad:
                              </b-col>
                              <b-col lg="7">
                                <b-form-group>
                                  <b-form-input
                                    type="number"
                                    id="firstName"
                                    placeholder="Cantidad"
                                    v-model="tagFeatures.quantity"
                                    min="1"
                                  >
                                  </b-form-input>
                                  <small>
                                    Disponibles {{ tagFeatures.stockAvailable }}
                                  </small>
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </div>
                          <hr class="bg-white" />
                          <div>
                            <CIcon
                              name="cilLocationPin"
                              size="lg"
                              height="48"
                              alt="Logo"
                              style="color: #c79c00"
                            />&nbsp
                            <strong>Ciudad:</strong>
                          </div>
                          <br>
                          <!-- city selection -->
                          <div>
                            <b-form-group
                              id="city"
                              
                              label-for="city"
                              v-if="cities.length > 0"
                            >
                              <b-form-select
                                id="city"
                                v-model.trim="$v.citySelected.$model"
                                :options="cities"  
                                @change="onUpdateCity"                
                                :class="{
                                  'form-control is-invalid': $v.citySelected.$error,
                                  'form-control is-valid': !$v.citySelected.$invalid
                                }"
                              ></b-form-select>
                              <b-form-invalid-feedback v-if="!$v.citySelected.required"
                                >Debes seleccionar tu ciudad de ubicación.</b-form-invalid-feedback
                              >
                            </b-form-group>
                          </div>

                          <div v-if="service.duration > 0">
                            <CIcon
                              name="cilClock"
                              size="lg"
                              height="48"
                              alt="Logo"
                              style="color: #c79c00"
                            />&nbsp
                            <span>
                              <strong>Duración: </strong>
                              {{
                                service.duration == 1
                                  ? service.duration + " hora"
                                  : service.duration + " horas"
                              }}
                            </span>
                          </div>

                          <div v-if="service.participants != null">
                            <CIcon
                              name="cilAnimal"
                              size="lg"
                              height="48"
                              alt="Logo"
                              style="color: #c79c00"
                            />&nbsp
                            <span>
                              <strong>Max. participantes: </strong>
                              {{ service.participants }}
                            </span>
                          </div>
                          <hr class="bg-white" />

                          <b-button
                            type="button"
                            @click="submit"
                            class="btn btn-md col-xs-12 col-sm-12 col-md-12 col-lg-12 s-bg"
                            v-if="service.type == 2"
                          >
                            <strong>Comprar</strong>
                          </b-button>

                          <!-- <b-button
                            type="button"
                            @click="buyServiceWp"
                            class="btn btn-md col-xs-12 col-sm-12 col-md-12 col-lg-12 s-bg"
                            v-else
                          >
                            <strong>Comprar</strong>
                          </b-button>                           -->
                          <div v-else>
                            <b-button
                              type="button"
                              @click="quoteService(service)"
                              class="btn btn-md col-xs-12 col-sm-12 col-md-12 col-lg-12 s-bg"
                              v-if="service.id != 35"
                            >
                              <strong>Cotizar</strong>
                            </b-button> 
                            <hr>
                          </div>
                              
                          <CButton
                            class="btn btn-md col-xs-12 col-sm-12 col-md-12 col-lg-12"
                            name="Whatsapp"
                            size="sm"
                            color="xing"
                            target="_blank"
                            @click.prevent="whatsapp()"
                          >
                            <CIcon size="lg" :name="'cib-Whatsapp'" />
                            Contáctanos
                          </CButton>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-jumbotron>

                <b-button
                  style="min-width:100%; margin-bottom:0.5rem; margin-top:-0.8rem"
                  type="button"
                  @click="showVideo"
                  class="btn btn-md col-xs-12 col-sm-12 col-md-8 col-lg-6"
                  v-if="service.youtubeUrl != null"
                >
                  <strong>Ver vídeo</strong>
                </b-button>
              </b-col>
              <!-- carousel and video button -->
              <b-col lg="7">
                <b-row>
                  <b-col lg="12" style="text-align: center;">
                    <b-carousel
                      id="carousel1"
                      style="text-shadow: 1px 1px 2px #333;"
                      class="profile-carousel-design"
                      controls
                      indicators
                      background="#ababab00"
                      :interval="5000"
                      img-width="1024"
                      img-height="480"
                      v-model="slide"
                      @sliding-start="onSlideStart"
                      @sliding-end="onSlideEnd"
                    >
                      <b-carousel-slide
                        :img-src="url"
                        v-for="url in service.photos"
                        v-bind:key="url"
                      ></b-carousel-slide>
                    </b-carousel>
                    <!-- <br />
                    <b-button
                      type="button"
                      @click="showVideo"
                      class="btn btn-md col-xs-12 col-sm-12 col-md-8 col-lg-6"
                      v-if="service.youtubeUrl != null"
                    >
                      <strong>Abrir vídeo</strong>
                    </b-button> -->
                  </b-col>
                </b-row>

                <!-- <b-embed
                                    type="iframe"
                                    aspect="16by9"
                                    :src="service.youtubeUrl"
                                    allowfullscreen
                                    class="video-props"
                ></b-embed>-->
              </b-col>
            </b-row>
          </section>

          <!-- About it -->
          <section>
            <b-row>
              <b-col md="10" offset-md="1">
                <hr />
                <b-row>
                  <b-col md="4" offset-lg="1" lg="3">
                    <span>
                      <h3>Acerca de</h3>
                    </span>
                  </b-col>
                  <b-col md="8">
                    <p v-for="item in service.about">{{ item }}</p>
                    <hr />
                    <br />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </section>

          <!-- Requirements -->
          <section>
            <b-row>
              <b-col md="10" offset-md="1">
                <b-row>
                  <b-col md="4" offset-lg="1" lg="3">
                    <span>
                      <h3>Requisitos</h3>
                    </span>
                  </b-col>
                  <b-col md="8">
                    <CListGroup>
                      <CListGroupItem v-for="item in service.requirements">
                        <!-- <CIcon :height="16" :name="'cilPin'"/>   -->
                        {{ item }}
                      </CListGroupItem>
                    </CListGroup>
                    <hr />
                    <br />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </section>

          <!-- Terms -->
          <section v-if="service.type == 2 && service.terms != null">
            <b-row>
              <b-col md="10" offset-md="1">
                <b-row>
                  <b-col md="4" offset-lg="1" lg="3">
                    <span>
                      <h3>Términos y condiciones</h3>
                    </span>
                  </b-col>
                  <b-col md="8">
                    <a @click="goTo('terms')">
                      <span class="text-link-color">Clic para ver detalle</span>
                    </a>
                    <br />
                  </b-col>
                </b-row>
                <br />
              </b-col>
            </b-row>
          </section>
        </CCard>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { BEmbed } from "bootstrap-vue";
import services from "../../utils/services.js";
import TagFeatures from "../modals/TagFeatures";
import {
  required,
  minLength,
  email,
  requiredUnless,
  requiredIf
} from "vuelidate/lib/validators";
export default {
  name: "service_detail",
  props: ["serviceId"],
  components: {
    Loading,
    BEmbed,
    TagFeatures
  },
  data() {
    return {
      loading: true,
      // wp: "https://api.whatsapp.com/send?phone=573194115792&text=Hola, ",
      
      slide: 0,
      service: null,
      bannerImage: require("@/assets/images/bonds3.png"),
      userCurrency: "COP",
      flagShowVideo: false,
      tagFeatures: {
        model: null,
        color: null,
        size: null,
        quantity: 1,
        stockAvailable: null,
        currencyCop: null
      },
      model: "1",
      flagTagFeatures: false,
      error: false,
      errMsg: "",
      cityId: null,
      wp: "https://api.whatsapp.com/send?phone=, ",
      cityPhone: "3194115792",
      countryIndicative: "57",
      cities: [{ value: null, text: 'Selecciona' }],
      citySelected: null,
    };
  },
 
  created() {
    this.cityId = this.$store.getters.cityId;
    if (this.cityId) {
      this.setServicePrice();
      this.setCityContact();
    }
  },
  computed: {
    socialMedia: function() {
      let url = "https://goguau.co" + this.$route.fullPath;
      let title =
        "Go Guau te ofrece: " +
        this.service.name +
        ". Reserva o compra en el siguiente link";
      let description =
        "Go Guau te ofrece: " +
        this.service.name +
        ". Reserva o compra en el siguiente link";
      let quote =
        "Go Guau te ofrece: " +
        this.service.name +
        ". Reserva o compra en el siguiente link";
      return { title, description, quote, url };
    }
  },
  validations: {
    tagFeatures: {
      model: {
        required
      },
      color: {
        required
      },
      size: {
        required
      },
    },
    citySelected: {
      required,
    }
  },
  methods: {
    whatsapp() {
      this.$gtag.event("serviceProfile", {
        event_category: "whatsapp"
      });

      let wpLink =
        this.wp 
        + this.countryIndicative 
        + this.cityPhone + "&text=Hola deseo información de "
        + this.service.name;
      
      window.open(wpLink, "_blank");
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd: function(slide) {
      this.sliding = false;
    },
    goTo(to) {
      if (to == "terms") {
        this.$router.push("/pages/termstag");
      }
    },
    buyServiceWp(msg) {
      let message = this.wp + "deseo comprar " + this.service.name;
      window.open(message, "_blank");
    },
    quoteService(service) {
      console.log("serviceId: ", +service.id);
      this.$gtag.event("serviceProfile", {
        event_category: "quoteService",
        event_label: service.id
      });
      this.$router.push({
        name: "service-quotation",
        params: {
          serviceId: +service.id
        }
      });
    },
    showVideo() {
      this.$gtag.event("serviceProfile", {
        event_category: "openVideo"
      });
      this.flagShowVideo = true;
    },
    submit() {
      // console.log(this.tagFeatures)
      this.$v.$touch();
      if (this.$v.tagFeatures.$invalid) {
        this.error = true;
        this.errMsg =
          "Para comprar es necesario seleccionar las características";
        this.scrollToTop();
      } else {
        this.tagFeatures["id"] = this.service.id;
        this.tagFeatures["name"] = this.service.name;
        this.tagFeatures["currencyCop"] = this.service.currencyCop;
        this.$store.commit("defItemFeatures", this.tagFeatures);
        this.$router.push({ path: "/services/buy" });
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    setServicePrice() {
      
      let serviceFound = services.find(
        service => service.id == this.serviceId
      );

      if (serviceFound.id == 1 || serviceFound.id == 2) {
        let priceFound = serviceFound.prices.find(
          price => price.cityId == this.cityId
        );
        if (priceFound) {
          serviceFound.currencyCop = priceFound.price;
        }
      }
      this.service = serviceFound
    
    },
    setCityContact() {
      
      this.$store
        .dispatch("fetchCities")
        .then(cities => {
          this.cities = [{ value: null, text: 'Selecciona' }]
          let cityFound = cities.find(city => city.value == this.cityId);
          if (cityFound) {
            this.cityPhone = cityFound.phone;
            this.countryIndicative = cityFound.indicative;
          }
          cities.map(city => {
            let cityItem = {
              value: city.value,
              text: city.label
            }
            this.cities.push(cityItem)
          })  
          this.citySelected = this.cityId
        })
        .catch(e => {
          console.log('Error fetchCities: ', e)
        }).finally(_=>this.loading = false)
    },   
    onUpdateCity(){
      this.cityId = this.citySelected
      if (this.service.id == 1 || this.service.id == 2) {
        let priceFound = this.service.prices.find(
          price => price.cityId == this.citySelected
        );
        if (priceFound) {
          this.service.currencyCop = priceFound.price;
        }
      }
      this.setCityContact()
    }
   
  }
};
</script>

<style scoped>
.wp-button {
  position: fixed;
  right: 2rem;
  top: 80%;
}

.service-banner {
  z-index: -1;
  background-blend-mode: screen;
  background-size: contain;
}
</style>
