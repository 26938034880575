<template>
  <div>   
    <b-modal
      title="Selecciona características"
      v-model="flagTagFeatures"      
      hide-header-close
      hide-footer
      no-close-on-backdrop>
      
       <b-row>
        <b-col lg="12">
            <p><strong>Modelo:</strong> Selecciona la forma</p>
        </b-col>
        <b-col lg="12">            
          <b-form-group>
            <b-form-radio-group
                v-model="modelSelected"
                :options="modelos.options"
                class="mb-3"
                value-field="value"
                text-field="label"
                disabled-field="notEnabled"
                @change="updateModel($event, modelos)">                                                  
              </b-form-radio-group>                                                               
            </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="12">
          <strong>Talla:</strong> Selecciona una variante
          
        </b-col>
        <b-col lg="12">
            <b-form-group>
              <b-form-radio-group
                v-model="sizeSelected"
                :options="tallas.options"
                class="mb-3"
                value-field="value"
                text-field="label"
                disabled-field="notEnabled"
                @change="updateModel($event, tallas)">                                                  
              </b-form-radio-group>                                                               
            </b-form-group>           
        </b-col>                       
      </b-row> 

      <b-row>
        <b-col lg="12"> 
          <strong>Dimensiones: </strong>  
          {{dimensions}}
        </b-col> 
      </b-row>
      
      <br>
      
      <b-row>
        <b-col lg="12">
          <strong>Color:</strong> Selecciona una variante
        </b-col>
        <b-col lg="12">
            <b-form-group>
              <b-form-radio-group
                v-model="colorSelected"
                :options="colores.options"
                class="mb-3"
                value-field="value"
                text-field="label"
                disabled-field="notEnabled"
                @change="updateModel($event, colores)">                                                  
              </b-form-radio-group>                                                               
            </b-form-group>
        </b-col>
        <b-col lg="6" offset-lg="3">          
          <img 
            :src="picture" 
            class="c-sidebar-brand-minimized" 
            width="100%"
            />      
        </b-col>

      </b-row>

     

      <!-- <div>
          <template v-for="(item, key) in radioBtns">
          
                <CRow form class="form-group" :key="item.name">
                  <b-col lg="12">
                    <strong>{{item.name}}</strong>
                  </b-col>
                  <CInputRadioGroup
                    class="col-sm-9"
                    :options="item.options"                    
                    :checked="`${item.checked}`"
                    :inline="true"
                    @update:checked="updateModel($event, item.key)"
                  />
                </CRow>
          </template>
      </div> -->                      
      <div>
        <strong>Unidades disponibles:</strong>  {{stock}}
      </div>

      <b-row>
        <b-col lg="10" offset-lg="1">
          <b-button 
            size="sm"       
            @click="apply" 
            class="mr-1 w-100">
            Aplicar
          </b-button> 
        </b-col>           
      </b-row>
    </b-modal>  
  </div>    
</template>

<script>
export default {
  name: "tagFeatures",
  props: ['flagTagFeatures', 'serviceId'],
  data(){
    return {     
      stockItems: [],
      modelSelected: null,
      colorSelected: null,
      sizeSelected: null,
      stockAvailable: null,
      picture: '',
      dimensions: '',
    }
  },
  created () {    
    this.fetchStock();             
  },
  computed : {
    radioBtns () {        
      return [
        this.modelos,  
        this.tallas,
        this.colores,              
      ]           
    },
    modelos () {
      let options = []
      this.stockItems.map((stock) => {
        const found = options.find(modelo => modelo.label == stock.modelo);
        if (found == undefined) {          
          options.push({
            label: stock.modelo,
            value: stock.modelo
          })
        }
      })            
      return {
        name: 'Modelo',
        key: 'model',
        subtitle: 'Selecciona una variante',
        options,
        checked: null,
      }
    },
    tallas () {
      let options = [];      
      const modelsFiltered = this.stockItems.filter(stock => 
        stock.modelo == this.modelSelected
      );
      modelsFiltered.map((item) => {
        const found = options.find(talla => talla.label == item.talla)    
        if (found == undefined) {            
          options.push({
            label: item.talla,
            value: item.talla,
            dimensions: item.dimensiones
          })
        }
      });
      return {
        name: 'Tamaño',
        key: 'size',
        subtitle: 'Selecciona una variante',
        options,
        checked: null,  
      }
    },
    colores () {
      let options = []
      let filter = {
        modelo: this.modelSelected,
        talla: this.sizeSelected,
      };

      let colorsFiltered = this.stockItems.filter(function(item) {
        for (var key in filter) {
          if (item[key] === undefined || item[key] != filter[key])
            return false;
        }
        return true;
      });

      colorsFiltered.map((item) => {
        const found = options.find(color => color.label == item.color)
        if (found == undefined) {          
          options.push({
            label: item.color,
            value: item.color,
            foto: item.foto,
          })
        }
      });

      return {        
        name: 'Color',
        key: 'color',
        subtitle: 'Selecciona una variante',
        options,
        checked: null,        
      }
    },
    stock: {
      get(){
        let cantidad = 0
        let filterQuantity = {
          modelo: this.modelSelected,
          talla: this.sizeSelected,
          color: this.colorSelected,
        };
        let quantityFiltered = this.stockItems.filter(function(item) {
          for (var key in filterQuantity) {
            if (item[key] === undefined || item[key] != filterQuantity[key])
              return false;
          }
          return true;
        });
        quantityFiltered.map((item) => {
          cantidad = item.cantidad
        });
        return cantidad
      },
      set(newValue) {
        return newValue
      }
     
    }

  },
  methods: {
    updateModel(value, input){       
      // console.log(value)
      let key = input.key
      let options = input.options
      // console.log(key)
      // console.log(options)
      if(key == 'model'){                
        this.$emit('modelSelected', value)                                
        this.sizeSelected = null
        this.colorSelected = null
        this.stock = null
        this.picture = ''
        this.dimensions = ''
        this.$emit('stockAvailable', this.stock)                       
      }else if(key == 'size'){        
        this.$emit('sizeSelected', value)
        this.colorSelected = null
        this.stock = null
        let found = options.find(option => option.label == value)
        if (found != null)
          this.dimensions = found.dimensions 
      }else if(key == 'color') {        
        this.$emit('colorSelected', value)
        this.$emit('stockAvailable', this.stock)
        let found = options.find(option => option.label == value)
        // console.log(found)
        if (found != null)
          this.picture = found.foto
      }
      
    },
    apply() {      
      this.$emit('flagTagFeatures', false);
    },
    fetchStock () {
      console.log('fetchStock')
      let data = {
        serviceId: this.serviceId
      }      
      console.log(data)
      let getStock = this.$firebase.functions().httpsCallable('getStock');
      getStock(data).then((response) => {
        this.stockItems = response.data.data;
        console.log(this.stockItems);
        this.loading = false;
      }).catch((error) => {
        console.log(error);
      });
    }
     
  }
}
</script>

<style scoped>
</style>
